import * as React from "react";
import logo from "../../assets/images/gangsoflagosfinal09-1@2x.png";
import h1PrimeVIdeoLogoM from "../../assets/h1PrimeVIdeoLogoM.svg";
import iconCheck from "../../assets/Icons/Icon-check.svg";
import iconCorrect from "../../assets/Icons/Icon-correct.svg";
import iconWrong from "../../assets/Icons/Icon-wrong.svg";
//import DescriptionSection from "../elements/DescriptionSection";
//import UploadH1 from "../elements/UploadH1";
import UploadControl from "../elements/UploadControl";
import Badwords from "../elements/badwords.json";
import UploadCloudinary from "../../cloudinary/UploadCloudinary";
import Cropper from 'react-easy-crop'
import Loader from "../elements/Loader";
import getCroppedImg from './crop'
const Home = () => {
    console.log('badwords', Badwords)
    const [uploadLoading, setUploadLoading] = React.useState(false);
    const [image, setImage] = React.useState('');
    const [crop, setCrop] = React.useState({ x: 0, y: 0 })
    const [zoom, setZoom] = React.useState(0)
    const [rotation, setRotation] = React.useState(0)
    const [croppedAreaPixels, setCroppedAreaPixels] = React.useState()
    const [imageReady, setImageReady] = React.useState(false);
    const [croppedImage, setCroppedImage] = React.useState()

    const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
        console.log(croppedArea, croppedAreaPixels)
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const [inputs, setInputs] = React.useState({
        name: '',
        error: false,
        checked: false,
    });


    const showCroppedImage = React.useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                image,
                croppedAreaPixels,
                rotation
            )
            console.log('donee', { croppedImage })
            setImageReady(false);
            setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, image, rotation])

    const onClose = React.useCallback(() => {
        setCroppedImage(null)
    }, [])

    const handleOnChange = (e) => {
        e.persist();
        setInputs((prev) => ({
            ...prev,
            [e.target.id]: e.target.value,
            error: false,
            checked: false,
        }));
    };

    const checkName = () => {
        if (inputs.name === '') {
            return;
        }

        const name = inputs.name.toLowerCase();
        if (Badwords.includes(name)) {
            setInputs((prev) => ({
                ...prev,
                error: true,
                checked: true,
            }));
        } else {
            setInputs((prev) => ({
                ...prev,
                error: false,
                checked: true,
            }));
        }
    }


    const chooseFile = React.useCallback(() => {
        const dropArea = document.querySelector("#drop_box");
        const button = dropArea.querySelector("button");
        const input = dropArea.querySelector("input");
        button.onclick();
        input.click();
    }, [])

    const handleFile = async e => {
        setUploadLoading(true);
        const file = e.target.files[0];
        const upload = await UploadCloudinary(file);
        console.log('upload', upload)
        setImage(upload.secure_url);
        const localStorageData = {
            image: upload.secure_url,
            title: inputs.name
        }
        localStorage.setItem('posterInformation', JSON.stringify(localStorageData));
        setUploadLoading(false);
        setImageReady(true)
        // showCroppedImage();
        // window.location = '/share';
    }

    if (uploadLoading) {
        return (<Loader />)
    }

    console.log(image)

    return (
        <>
        
            <div className="container g-0">
                <main>
                {!imageReady && (
                    <header>

                        <div className="py-5 text-center">
                            <img className="d-block mx-auto mb-4" src={logo} alt="" width="329" height="60" />
                        </div>


                        <div className="row px-3">
                            <div className="col-md-10 mx-auto">
                                <div className="header-description-section">
                                    <div className="decsription-text">
                                        Enter your location into the field below to have it on your photo
                                        filter
                                    </div>
                                </div>

                                <div className="section-head pt-md-3">
                                    <h2>GANG OF ...</h2>
                                </div>
                       

                        {/* description */}
                        


                        <div className=" mb-3">
                                <div className="row g-0">
                                    <div className="col-10 ">
                                        <input type="text"
                                            className={`form-control location-input ${inputs.error ? 'is-invalid' : ''}`}
                                            //className="form-control location-input"
                                            placeholder="Enter location..." 
                                            aria-label="Enter location..." 
                                            aria-describedby="button-addon2"
                                            id="name"
                                            name="name"
                                            onChange={handleOnChange}
                                            required />
                                    </div>
                                    
                                    <div className="col-1">
                                    
                                    {!inputs.checked ? <img src={iconCheck} onClick={checkName} alt="" /> : null}
                                        {inputs.checked &&
                                            <img src={inputs.error ? iconWrong : iconCorrect} onClick={checkName} alt="" />
                                        }
                                    </div>

                                </div>
                                {inputs.error && <div className="invalid-feedback text-center">
                                    Ogbeni, no swear words allowed
                                </div>}

                            </div>
                                

                            <UploadControl disabled={!inputs.name ? true : false} />

                            <div className="text-center drop_box" id="drop_box">
                                <input name="file" type="file" onChange={handleFile} hidden accept='png, jpg' id="fileID" style={{ display: 'none' }} />
                                <br />
                                {inputs.name !== '' && inputs.checked && !inputs.error && (
                                    <button onClick={chooseFile} className="btn fw-bold bg-black btn-upload-picture">Upload Image</button>
                                )}
                            </div>


                            <div className="text-center my-5">
                            
                                    <img
                                    alt=""
                                    src={h1PrimeVIdeoLogoM}
                                    />
                                
                            </div>
                            

                       

                    </div>
                </div>
            </header>
      
                     )}

                    {imageReady && (
                        <>
                            <header>
                                <div className="crop-container">
                                    <Cropper
                                        image={image}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={4 / 3}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onRotationChange={setRotation}
                                        onZoomChange={setZoom} />
                                </div>
                                <div className="controls">
                                    <input
                                        type="range"
                                        value={zoom}
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        aria-labelledby="Zoom"
                                        onChange={(e) => {
                                            setZoom(e.target.value);
                                        }}
                                        className="zoom-range" />
                                         <button
                                    onClick={showCroppedImage}
                                    className={'btn btn-primary btn-sm'}
                                    >
                                    Crop
                                    </button>
                                </div>
                                
                            </header>

                            <header>
                           
                            </header>

                            {croppedImage && (
                            <header>
                            
                                <img src={croppedImage} alt="Cropped" className={'img-responsive'} />
                            
                            </header>
                            )}
                            
                        </>

                    )}


                </main>
            </div>
           
        </>

    );
};
export default Home;
