import * as React from "react";
import materialSymbolsscre from "../../assets/images/materialsymbolsscreenlockportraitoutline.svg";
import materialSymbolsscre1 from "../../assets/images/materialsymbolsscreenlockportraitoutline1.svg";
import line2 from "../../assets/line2.svg";
const UploadControl = ({disabled}) => {
  console.log('disabled', disabled)
  return (
    <div className="image-upload-box mb-4">
    <div className="image-upload-box-inner p-5">

        <div className="row">

            <div className="col-md-11 mx-auto">
                <div className="row">
                    <div className="col-5">
                    <div className="text-center">
                      <img className="image" src={materialSymbolsscre1} alt="" />
                      <p>Upload image from photo library</p>
                    </div>
                    </div>
                    <div className="col-1 content-center upload-box-middle" >
                        <img className="upload-box-line" alt="" src={line2} />
                        <span className="p-2" style={{color: "#635c5c82"}} >OR</span>
                        <img className="upload-box-line" alt="" src={line2} />
                    </div>
                    <div className="col-5">
                        <div className="text-center">
                        <img className="image" src={materialSymbolsscre} alt="" />
                        <p>Take a portrait photo</p>
                        </div>
                    </div>
                </div>

            </div>
            
            
          </div>

    </div>
</div>
    
  );
};
export default UploadControl;
