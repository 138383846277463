
import React from 'react'
const Loader = () => {
    return (
        <div className='container text-center' style={{ marginTop: '100px', color: '#fff' }}>
        <div className="spinner-border spinner-border-sm spinner" style={{ marginTop: '10px', color: '#fff' }} role="status">
          </div>
          <h4>hold tight we are making your poster 🪄</h4>
      </div>
    );
}

export default Loader;