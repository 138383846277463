import * as React from "react";
import logo from "../../assets/images/gangsoflagosfinal09-1@2x.png";
import poster from "../../assets/images/poster.svg";
import facebookIcon from "../../assets/Icons/Share-facebook.svg";
import whatsappIcon from "../../assets/Icons/Share-whatsapp.svg";
import instagramIcon from "../../assets/Icons/Share-instagram.svg";
import twitterIcon from "../../assets/Icons/Share-twitter.svg";
import h1PrimeVIdeoLogoM from "../../assets/h1PrimeVIdeoLogoM.svg";

const Share = () => {
    const data = JSON.parse(localStorage.getItem('posterInformation'));
    return (
        <div class="container">
            <main>
                <header>
                    <div class="py-5 text-center">
                        <img class="d-block img-fluid mx-auto mb-4" src={logo} style={{ padding: '10px' }} alt="" width="329" height="60" />
                    </div>

                    <img class="d-block img-responsive mx-auto container" src={data?.image} style={{ width: '100%' }} alt='' />

                    <div class="sub-head px-3 text-center pt-5 container">
                        <h1 className="confam">STREETS DON CONFAM YOU</h1><br/>
                        <div className="row container mx-auto">
                            <div className="col-md-3">
                                <img class="d-block img-responsive mx-auto " src={facebookIcon} alt='share to facebook' />
                            </div>
                            <div className="col-md-3">
                                <img class="d-block img-responsive mx-auto " src={twitterIcon} alt='share to twitter' />
                            </div>
                            <div className="col-md-3">
                                <img class="d-block img-responsive mx-auto " src={whatsappIcon} alt='share to whatsapp' />
                            </div>
                            <div className="col-md-3">
                                <img class="d-block img-responsive mx-auto " src={instagramIcon} alt='share to instagram' />
                            </div>
                        </div>
                        <a download href={data?.image} rel="noreferrer" target={'_blank'} className="download-btn">Download Image</a>

                        <p >
                            <a href="#how-to-play" class="btn container amazon-btn">
                                <svg width="40" height="50" viewBox="0 0 40 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.02469 48.7868C4.70782 49.6427 3.37383 49.6914 2.02272 48.9329C0.674239 48.177 0 47.009 0 45.4287V4.53986C0 2.95961 0.674239 1.79023 2.02272 1.03171C3.37383 0.275828 4.70782 0.32587 6.02469 1.18184L38.2222 21.6263C39.4074 22.4164 40 23.5357 40 24.9843C40 26.4329 39.4074 27.5522 38.2222 28.3423L6.02469 48.7868Z" fill="white" />
                                </svg> 	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                Watch Now on Prime Video</a>
                        </p>

                        <p>
                            <a href="/upload" style={{ width: '100%' }} class="container btn btn-lg btn-dark fw-bold bg-black get-started ">Restart</a>
                        </p>

                        <div className="text-center" style={{marginBottom: '20px'}}>
                            <img
                                style={{
                                    width: "178px",
                                    height: "66px",
                                    objectFit: "cover",
                                    marginTop: '20px'
                                }}
                                src={h1PrimeVIdeoLogoM}
                                alt="" />
                        </div>
                    </div>
                </header>


            </main>

        </div>
    );
};
export default Share;
