import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Share from './components/Share';
import Upload from './components/upload';
import ImageResizeCropComponent from './components/upload/crop';

export default function App() {
  return (
    <div>
      <Router>
            <Routes>
						{/* <Route path="/login"  element={<SignIn/>} /> */}
						<Route path="/" element={<Home/>} />
						<Route path="/upload" element={<Upload/>} />
						<Route path="/crop" element={<ImageResizeCropComponent/>} />
						<Route path="/share" element={<Share/>} />
            </Routes>
				</Router>
      
     
    </div>
  );
}
