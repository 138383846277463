import * as React from "react";
import logo from "../../assets/images/gangsoflagosfinal09-1@2x.png";
//import poster from "../../assets/images/poster.svg";
import imageBG from "../../assets/images/rectangle-541@2x.png";
import footerLogo from "../../assets/images/prime-video-logo-mature-market-pitch-dark-blue-rgb-18@2x.png";
const Home = () => {
  return (
    <div className="container g-0">
    <main>
        <header>
            <div className="py-5 text-center">
                <img className="d-block img-fluid mx-auto mb-4" src={logo} style={{padding: '10px'}} alt="" width="329" height="60"/>
            </div>
            
            <div className="row px-4">
                    <div className="col-md-8 col-sm-8 mx-auto">
                        <div className="parent">
                            <img src={imageBG} alt="" className="img" />
                                <div className="the-gang-child">
                                    <div className="gangtext text-white">
                                       Gangs
                                    </div>
                                    <div className="bg-black location d-flex">
                                        <div className="location-of text-white">
                                            <p>Of</p>
                                        </div>
                                        <div className="location-text">
                                            <h2>OJUELEGBE</h2>
                                        </div>
                                        
                                    </div>
                                    <span className="hashtag text-white"> #GangsOfLagosonPrime</span>
                                </div>
                        </div>
                    </div>
                </div>

                <div className="sub-head px-5 text-center pt-5">
                    <h1>Rep your hood!</h1>
                    <p className="lead px-md-5">Overlay our sticker on your photo and show your friends who rules
                        the streets!</p>
                    <p>
                    <a href="/upload" className="btn btn-lg btn-dark fw-bold bg-black play-now">play now!</a>
                    </p>
                    <p >
                        <a href="#how-to-play" className="btn fw-bold  how-to-play">how to play</a>
                    </p>
                </div>

        </header>

        <div className="main-content" id="how-to-play">
            <div className="container ">
                <div className="row">
                    <div className="col-md-11 mx-auto">
                
                        <div className="content-item bg-white my-4">
                            <div className="row">
                                <div className="col-2 col-md-3">
                                    <div className="circle">
                                        <div className="number">1</div>
                                    </div>
                                </div>
                                <div className="col-10 col-md-9 pt-md-4">
                                    <div className="content ">
                                        <h3>Enter your gang location</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div className="content-item bg-white my-4">
                            <div className="row">
                                <div className="col-2 col-md-3 ">
                                <div className="circle">
                                    <div className="number">2</div>
                                </div>
                                </div>
                                <div className="col-10 col-md-9  pt-md-3">
                                    <div className="content">
                                        <h3>UPLOAD YOUR GROUP PHOTO (OR SINGLE IF YOU ROLL SOLO)</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div className="content-item bg-white my-4">
                            <div className="row">
                                <div className="col-md-3 col-2">
                                <div className="circle">
                                    <div className="number">3</div>
                                </div>
                                </div>
                                <div className="col-md-9 col-10 pt-md-4">
                                    <div className="content ">
                                        <h3>DOWNLOAD / SHARE ON SOCIAL MEDIA</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <p className="text-center">
                            <a href="/upload" className="btn fw-bold bg-yellow btn-get-started">Get Started</a>
                        </p>
        
                       
                      </div>  

                </div>
            </div>  
          </div>
  
      
    </main>
  
    <div className="footer py-5 text-center">
        <div className="footer-logo">#GangsOfLagos</div>
        <img
          alt=""
          src={footerLogo}
          width="120px"
        />
      </div>
  </div>
  );
};
export default Home;
